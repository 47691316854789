import { graphql, useStaticQuery } from 'gatsby'

export const Socialcardportfolio = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "websolverproportfolios.jpg" }) {
          childImageSharp {
            fluid {
              src
              presentationHeight
              presentationWidth
            }
          }
        }
      }
    `
  )

  const imageData = data.desktop.childImageSharp.fluid
  return imageData
}
