import React from 'react'
import Portfoliogrid from '../components/homepageComponent/portfoliogrid'
import SEO from '../components/seo'
import {Socialcardportfolio} from '../components/homepageComponent/bgcomponents/Socialcardportfolio'

const Portfolios = () => {
  return (
    <div>
      <SEO title='Portfolios' image={Socialcardportfolio()} pathname='https://websolverpro.com/portfolios' />
        <div className="portfolio-text">
            
            <h2 className="text-center text-fadeblack py-7 lg:py-9 xl:py-24 w-full p-2 lg:p-12">Portfolios...</h2>
        </div>
      <div className='portfolio-con pb-24 px-2 lg:px-16'>
        <Portfoliogrid />
      </div>
    </div>
  )
}

export default Portfolios
